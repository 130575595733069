.clinic-selection-screen {
    text-align: center;
    /* margin: 20px; */
  }
  
  .clinic-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 20px;
    justify-items: center;
  }
  
  .pagination-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-top: 20px; */
  }
  
  .back-button {
    background-color: #E0E0E0;
    color: black;
  }
  
  .page-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .page-nav-button {
    background-color: #00bd84;
    color: white;
  }
  
  .page-number-button {
    background-color: #E0E0E0;
    color: black;
  }
  
  .page-number-button.active {
    background-color: #00bd84;
    color: white;
  }
  
  .clinic-button {
    width: 90%;
    height: 10vh;
    background-color:#00bd84 !important;
    color: white;
    font-size: 24px !important;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-color: #00bd84 !important;
    font-weight: bold !important;
  }

  .clinic-button-doctor {
    width: 90%;
    height: 15vh;
    background-color:#00bd84 !important;
    color: white;
    font-size: 24px !important;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-color: #00bd84 !important;
    font-weight: bold !important;
  }