.queue-bg {
  padding: 40px;
  width: 100%;
  min-height: 100vh;
  background: url("../../../public/img/background/bg-tv.svg") no-repeat left
      bottom,
    linear-gradient(180deg, #ffffff 0%, #94c7e4 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.queue-container {
  width: 100%;
}

.queue-card {
  min-height: 270px;
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.queue-card-v2 {
  min-height: 270px;
  background: #ffffff;
  border-radius: 16px;
}

.queue-card-v2-title {
  height: 100px;
  background: radial-gradient(
    121.17% 215.42% at 97.15% 60.69%,
    #293894 14.37%,
    #5796e2 92.92%
  );
  border-radius: 16px 16px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.queue-card-v2-body {
  height: calc(270px - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.queue-card-v3 {
  padding: 30px;
  height: 100%;
  min-height: 270px;
  max-height: 564px;
  background: #ffffff;
  border-radius: 16px;
}

.queue-table-header {
  height: 40px;
  width: 100%;
  display: flex;
}

.queue-table-row-body {
  height: 100%;
  max-height: calc(100% - 40px);
  overflow: auto;
}

.queue-table-body {
  height: 60px;
  width: 100%;
  border-bottom: 2px solid #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.queue-table-body-v2 {
  height: 60px;
  width: 100%;
  border-bottom: 2px solid #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
}

.queue-word {
  height: 300px;
  font-size: 75px;
  color: mediumseagreen;
}

.box-down {
  padding: 0.75rem;
  margin: 0.5rem;
  background-color: #ffffff;
  border: 1px solid #c9c1d5;
  color: #000000;
  width: 100%;
  height: fit-content;
  font-size: 2vw;
}

.queue-card {
  min-height: 270px;
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topbar-display {
  top: 20px;
  width: 100%;
}