.button-control {
  height: 40px;
  width: 100%;
  color: #ffffff !important;
  font-weight: bold !important;
  border: 1px solid #00bd84 !important;
  border-radius: 50px !important;
  background-color: #00bd84 !important;
}

.button-control-h-default {
  height: 120px;
  font-size: 48px !important;
  width: 80%;
  color: #ffffff !important;
  font-weight: bold !important;
  border: 1px solid #00bd84 !important;
  border-radius: 16px !important;
  background-color: #00bd84 !important;
}

.button-control-h-100 {
  height: 120px;
  font-size: 44px !important;
  width: 100%;
  color: #ffffff !important;
  font-weight: bold !important;
  border: 1px solid #00bd84 !important;
  border-radius: 99px !important;
  background-color: #00bd84 !important;
}

.background-print {
  background-color: gray !important;
}

.button-control-h-80 {
  height: 80px;
  font-size: 40px !important;
  width: 80%;
  color: #ffffff !important;
  font-weight: bold !important;
  border: 1px solid #00bd84 !important;
  border-radius: 16px !important;
  background-color: #00bd84 !important;
}

.button-patient-control-h-100 {
  height: 140px;
  font-size: 30px !important;
  width: 100%;
  color: #ffffff !important;
  font-weight: bold !important;
  border: 1px solid #00bd84 !important;
  border-radius: 16px !important;
  background-color: #00bd84 !important;
}

.button-patient-control-h-80 {
  height: 100px;
  font-size: 24px !important;
  width: 100%;
  color: #ffffff !important;
  font-weight: bold !important;
  border: 1px solid #00bd84 !important;
  border-radius: 16px !important;
  background-color: #00bd84 !important;
}

.button-description-admission {
  font-size: 18px; 
  font-weight: 500;
}

.button-description-title {
  font-size: 40px; 
  font-weight: bold !important;
}

.button-back {
  background-color: #ffffff !important;
  color: #040505 !important;
  border-radius: 99px !important;
  width: 90%;
  height: 50px;
} 

.title-back-button {
  font-size: 24px;
}


@media (max-width: 576px) { 
  .button-control-h-default {
    height: 100px;
    font-size: 32px !important;
    width: 100%;
  }
  .button-control-h-100 {
    height: 80px;
    font-size: 28px !important;
    width: 100%;
  }
  .button-control-h-80 {
    height: 80px;
    font-size: 28px !important;
    width: 100%;
  }
  .button-patient-control-h-100 {
    height: 80px;
    font-size: 24px !important;
    width: 100%;
  }

  .button-back {
    font-size: 12px !important;
    width: 120%;
    height: 50px !important;
    border-radius: 99px !important;
  }

  .title-back-button {
    font-size: 9px;
  }
}
