.title-hospital-name {
    font-weight: bold !important;
    font-size: xxx-large;
    text-align: center !important;
    margin-bottom: 30px;
    /* display: flex !important;
    justify-content: center !important;
    margin: 0; */
}

.title-hospital-service {
    font-size: x-large !important;
    text-align: center !important;
    font-weight: 500;
}