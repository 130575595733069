.select-control {
  height: 40px;
  color: #000000 !important;
  border: 1px solid #cccccc !important;
  border-radius: 50px !important;
  padding: 0 20px !important;
  cursor: pointer;
}

.select-control-h-default {
  height: 84px;
  font-size: 24px !important;
  font-weight: bold !important;
  border: 1px solid #999999 !important; /* Menambahkan border abu-abu */
  border-radius: 99px !important; /* Menambahkan border-radius 16px */
  background-color: transparent !important; /* Menjadikan background transparan */
  text-align: center; /* Ensure text alignment in the center */
  color: #999999 !important;
  /* font-family: 'Montserrat', sans-serif !important; */
}
