.custom-dropdown {
    font-size: 30px !important;
    font-weight: bold !important;
    border: 1px solid #999999 !important;
    text-align: center;
    position: relative;
    cursor: pointer;
    padding: 10px;
    height: 84px;
    border-radius: 99px !important; /* Menambahkan border-radius 16px */
    color: #00bd84 !important;
    display: flex;
    align-items: center !important;
    background-color: white !important;
  }

  .custom-dropdown .select-toggle {
    display: inline-block;
    width: 100%;
  }
  
  .custom-dropdown .options-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 10px;
    position: absolute;
    background-color: white;
    border: 1px solid #999999;
    border-radius: 16px;
    padding: 10px;
    width: 100%;
    z-index: 1;
    top: 30%;
    font-size: 24px;
  }

  .custom-dropdown .options-container-doctor {
    /* display: grid; */
    /* grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
    /* gap: 10px; */
    position: absolute;
    background-color: white;
    border: 1px solid #999999;
    border-radius: 16px;
    padding: 10px;
    width: 100%;
    z-index: 1;
    top: 100%;
    font-size: 28px;
    font-weight: 400;
    overflow: scroll;
    height: 50vh;
  }
  
  .custom-dropdown .option {
    padding: 10px 20px;
    border: 1px solid transparent;
    border-radius: 16px;
    cursor: pointer;
    font-weight: 500;
    color: #333333;
  }
  
  .custom-dropdown .option.selected {
    background-color: #00bd84;
    color: rgb(255, 255, 255) !important;
    border-color: #00bd84;
    font-weight: bold;
  }
  
  .custom-dropdown .option.disabled {
    background-color: #cccccc; /* Warna abu-abu untuk opsi dinonaktifkan */
    color: #999999 !important; /* Warna teks abu-abu untuk opsi dinonaktifkan */
    cursor: not-allowed; /* Kursor tidak diizinkan untuk opsi dinonaktifkan */
}

 .custom-dropdown .selected {
    color: #00bd84 !important;
  }

  .custom-dropdown .options-container.single-option {
    display: block;
}