.patient-bg {
  padding: 24px;
  width: 100%;
  min-height: 100vh;
  background: url("../../../public/img/background/bg-login-back.svg") no-repeat
      left bottom,
    linear-gradient(180deg, #ffffff 0%, #94c7e4 100%);
  display: flex;
  flex-direction: column;
}

.patient-header {
  margin-top: 24px;
}

.patient-container {
  width: 100%;
  flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.font-family-patient-display {
  font-family: 'Montserrat', sans-serif !important;
}

.patient-display-title {
  font-size: 30px;
  font-weight: bold !important;
}


.kiosk-font-face {
  font-family: 'SF Mono', monospace;
}

